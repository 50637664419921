import { Button, Form, Input } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { openNotificationWithIcon } from "../../../utils/helper";

const Webauth = () => {
  const [authResult, setAuthResult] = useState("");
  const [createdCredentials, setCreatedCredentials] = useState<any>(null);

  const state = useSelector((state: RootState) => ({
    user: state.user.user,
  }));

  const { user } = state;

  const generateRandomChallenge = () => {
    let length = 32;
    let randomValues = new Uint8Array(length);
    window.crypto.getRandomValues(randomValues);
    console.log(randomValues);
    return randomValues;
  };

  const createPasskey = async () => {
    if (
      !navigator.credentials ||
      !navigator.credentials.create ||
      !navigator.credentials.get
    ) {
      openNotificationWithIcon(
        "info",
        "Biometric Authentication",
        "Your browser does not support the Web Authentication API"
      );
    }

    try {
      const publicKey: any = {
        challenge: new Uint8Array([
          21, 31, 105, 21, 31, 105, 21, 31, 105, 21, 31, 105, 21, 31, 105, 21,
          31, 105, 21, 31, 105, 21, 31, 105, 21, 31, 105, 21, 31, 105,
        ]),
        rp: {
          name: "Lucrestack",
          //Lucrestack.com
          id: window.location.hostname,
        },
        user: {
          id: new Uint8Array(16),
          name: user?.email ?? "johndoe@example.com",
          displayName: user?.fullame ?? "John Doe",
        },
        pubKeyCredParams: [
          { type: "public-key", alg: -7 },
          { type: "public-key", alg: -257 },
        ],
        timeout: 60000,
        authenticatorSelection: {
          residentKey: "preferred",
          requireResidentKey: false,
          userVerification: "preferred",
        },
        attestation: "none",
        extensions: { credProps: true },
      };

      let credentials: any = await navigator.credentials.create({
        publicKey,
      });

      // Save credentials (in a real app, you would store this in your DB)
      // window.currentPasskey = credentials;

      const passkey: any = {
        user: credentials?.publicKey?.user,
        rawId: credentials?.rawId,
        publicKey: credentials?.publicKey,
      };

      console.log({
        passkey,
      });

      setCreatedCredentials(credentials);

      setAuthResult("Passkey created successfully");
    } catch (error) {
      console.error(error);
      setAuthResult("Error creating passkey");
    }
  };

  const verifyPasskey = async () => {
    try {
      let credentials = await navigator.credentials.get({
        publicKey: {
          challenge: generateRandomChallenge(),
          allowCredentials: [
            // { type: "public-key", id: window.currentPasskey.rawId },
            {
              type: "public-key",
              id: createdCredentials?.rawId,
            },
          ],
        },
      });

      console.log("verifyPasskey", { credentials });
      alert("Biometric authentication successful!");
    } catch (error) {
      console.error(error);
      alert("Biometric authentication failed!");
    }
  };

  return (
    <div className="mt-2">
      <div className="w-11/12 mx-auto lg:w-3/12">
        <div className="flex items-baseline justify-between mt-10">
          <h1 className="mb-4 text-3xl font-semibold font-ibmplexsans text-appcolorblue">
            Biometric Authentication
          </h1>

          <span
            className="px-2 py-1 text-xs font-black cursor-pointer rounded-2xl bg-appcoloryellow text-appcolorblue"
            onClick={() => {}}
          >
            Skip
          </span>
        </div>

        <div>
          <Form className="w-full" layout="vertical">
            <Form.Item
              label="Email Address"
              name="email"
              rules={[
                {
                  type: "email",
                  message: "Invalid Email",
                },
                {
                  required: true,
                  message: "Email is required",
                },
              ]}
            >
              <Input placeholder="Email" value={user?.email} disabled />
            </Form.Item>

            <div className="flex justify-between">
              <div className="w-[45%]">
                <Button
                  className=""
                  onClick={() => {
                    createPasskey();
                  }}
                >
                  Register
                </Button>
              </div>
              <div className="w-[45%]">
                <Button
                  onClick={() => {
                    verifyPasskey();
                  }}
                >
                  Verify
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Webauth;
