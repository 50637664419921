import React from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../../store/userSlice/userSlice";
import useOneSignal from "../../../customhooks/useOneSignal";
import { RootState } from "../../../store/store";

const Onboardsuccess = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const state = useSelector((state: RootState) => ({
    subscribed: state.user.subscribed,
  }));

  const { subscribed } = state;

  const { clearUserTags } = useOneSignal(subscribed);

  return (
    <div className="mt-2">
      <div className="flex justify-center ">
        <img src="/img/logo/newlogo.png" alt="logo" className="w-16" />
      </div>

      <h1 className="mb-4 text-2xl font-semibold text-center font-ibmplexsans text-appcolorblue">
        Congratulations👋
      </h1>

      <p className="w-11/12 mx-auto text-sm font-normal text-center font-poppins mb-14 text-appcolorgrey lg:w-3/12">
        Your account has been successfully created, you can now access your
        dashboard, kindly complete the KYC to enable your full banking
        experience.
      </p>

      <div className="flex justify-center mb-14">
        <img src="/img/vectors/onboardsuccess.svg" alt="onboardsuccess" />
      </div>

      <div className="flex flex-col items-center justify-center w-9/12 mx-auto mb-8 lg:w-3/12">
        <Button
          className="mb-2"
          onClick={() => {
            dispatch(logoutUser());
            clearUserTags();
            navigate("/signin");
          }}
        >
          Go To Login
        </Button>
      </div>
    </div>
  );
};

export default Onboardsuccess;
