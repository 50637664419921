import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEnabledTransactionPin } from "../../../customhooks/useEnabledTransactionPin";
import { Button, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../../store/userSlice/userSlice";
import { RootState } from "../../../store/store";
import { updatePinOnPayment } from "../../../api/base/base";
import { openNotificationWithIcon } from "../../../utils/helper";

const Manageaccount = () => {
  const navigate = useNavigate();
  const hasPinEnabled = useEnabledTransactionPin();
  const dispatch = useDispatch();

  const state = useSelector((state: RootState) => ({
    user: state.user.user,
  }));

  const { user } = state;

  const [loading, setLoading] = useState(false);

  const onFinish = async () => {
    setLoading(true);

    try {
      const res = await updatePinOnPayment({
        enable: "No",
      });

      const { status, data } = res.data;

      if (status === "success") {
        openNotificationWithIcon(
          "success",
          `Disable Transaction PIN`,
          res.data.message
        );

        dispatch(
          updateUser({
            user: {
              ...user,
              security: data?.security,
            },
          })
        );

        navigate("/dashboard");
      } else {
        openNotificationWithIcon(
          "error",
          `Disable Transaction PIN`,
          res.data.message
        );
      }

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      message.error(`Something went wrong:  Disable Transaction PIN`);
    }
  };

  return (
    <div className="mt-4 mb-8">
      <div className="flex justify-center ">
        <img src="/img/logo/newlogo.png" alt="logo" className="w-16" />
      </div>
      <h1 className="mb-4 text-2xl font-semibold text-center font-ibmplexsans text-appcolorblue">
        Manage Account
      </h1>
      <div className="w-11/12 mx-auto lg:w-3/12">
        <div
          className="flex items-start px-8 py-3 mb-4 border rounded-lg shadow-lg cursor-pointer bg-appcolorwhite border-appcolorlightharsh"
          onClick={() => {
            navigate("/dashboard/change-password");
          }}
        >
          <div>
            <h1 className="mb-1 text-2xl font-semibold font-ibmplexsans text-appcolorblue">
              Change PIN
            </h1>
            <p className="text-sm text-appcolorlightharsh">
              Change or update your pin.
            </p>
          </div>
        </div>

        <div
          className="flex items-start px-8 py-3 mb-4 border rounded-lg shadow-lg cursor-pointer bg-appcolorwhite border-appcolorlightharsh"
          onClick={() => {
            navigate("/dashboard/update-email");
          }}
        >
          <div>
            <h1 className="mb-1 text-2xl font-semibold font-ibmplexsans text-appcolorblue">
              Update Email
            </h1>
            <p className="text-sm text-appcolorlightharsh">Update your email</p>
          </div>
        </div>

        <div
          className="relative flex items-start px-8 py-3 mb-4 border rounded-lg shadow-lg cursor-pointer bg-appcolorwhite border-appcolorlightharsh"
          onClick={() => {
            navigate("/dashboard/update-payment-pin");
          }}
        >
          <div>
            <h1 className="mb-1 text-2xl font-semibold font-ibmplexsans text-appcolorblue">
              {hasPinEnabled ? "Update" : "Enable"} Transaction PIN
            </h1>
            <p className="text-sm text-appcolorlightharsh">
              Update your transaction security
            </p>
          </div>
        </div>

        {hasPinEnabled && (
          <div className="">
            <Button
              htmlType="submit"
              className="mt-4 mb-4 red-btn"
              onClick={() => {
                onFinish();
              }}
              loading={loading}
              disabled={loading}
            >
              Disable Transaction PIN
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Manageaccount;
