import React from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

const Requestinvestmentsuccess = () => {
  const navigate = useNavigate();

  return (
    <div className="mt-2">
      <div className="flex justify-center ">
        <img src="/img/logo/newlogo.png" alt="logo" className="w-16" />
      </div>

      <h1 className="mb-4 text-2xl font-semibold text-center font-ibmplexsans text-appcolorblue">
        Investment Successful
      </h1>

      {/* <p className="w-11/12 mx-auto mb-4 text-sm font-normal text-center font-poppins text-appcolorgrey lg:w-3/12">
        Full Repayment Received
      </p> */}

      <div className="flex justify-center mb-0">
        <img src="/img/vectors/loanrequestsuccess.png" alt="paymentsuccess" />
      </div>

      <h1 className="mb-2 text-xl font-semibold text-center font-ibmplexsans text-appcolorblack">
        Your Investment Application has been sent
      </h1>

      {/* <p className="w-11/12 mx-auto text-sm font-normal text-center font-poppins mb-14 text-appcolorgrey lg:w-3/12">
        We will review and respond qickly as soon as we also receive a
        confirmation from your Healthcare Provider.
      </p> */}

      <div className="flex flex-col items-center justify-center w-9/12 mx-auto mb-8 lg:w-3/12">
        <Button
          className="mb-2"
          onClick={() => {
            navigate("/dashboard");
          }}
        >
          Go To Dashboard
        </Button>
      </div>
    </div>
  );
};

export default Requestinvestmentsuccess;
