import React, { useState, useEffect } from "react";
import { nanoid } from "nanoid";
import { message, Radio } from "antd";
import type { RadioChangeEvent } from "antd";
import Repayloanamount from "../../../components/repayloanamount/repayloanamount";
import { getLoanBalance, getLoanDetails } from "../../../api/loan/loan";
import Skeletonloader from "../../../components/skeletonloader/skeletonloader";
import { commaNumber } from "../../../utils/helper";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { ArrowLongLeftIcon } from "@heroicons/react/24/outline";

const Repayloan = () => {
  const { reference_code } = useParams();
  const navigate = useNavigate();

  const [tabs, setTabs] = useState([
    {
      id: nanoid(),
      name: "Full Repayment",
      active: true,
    },
    {
      id: nanoid(),
      name: "Partial Repayment",
      active: false,
    },
  ]);
  const [visibleLoanAmount, setVisibleLoanAmount] = useState(false);
  const [loadingloan, setLoadingloan] = useState(false);
  const [loans, setLoans] = useState<any>(null);
  const [methodValue, setMethodValue] = useState("");

  const closeVisibLoanAmount = () => {
    setVisibleLoanAmount(false);
  };

  const switchTabs = (valueIndex: number) => {
    const newTabs = [...tabs];
    newTabs.forEach((item, index) => {
      item.active = false;
      if (valueIndex === index) {
        item.active = true;
      }
    });
    setTabs(newTabs);
    setMethodValue("");
  };

  const onChange = (e: RadioChangeEvent) => {
    setMethodValue(e.target.value);
  };

  const getLoan = async () => {
    setLoadingloan(true);
    try {
      const res = await getLoanDetails({
        currency: "ngn",
        reference_code,
      });

      const { status, data } = res.data;

      if (status === "success") {
        if (data?.repayment_amount !== 0) {
          setLoans(data ?? []);
        }
      } else {
        message.warning(`Get loan, ${res.data.message}`);
      }

      setLoadingloan(false);
    } catch (error) {
      setLoadingloan(false);
      message.error("Something went wrong: Get loan");
      navigate("/dashboard/loan");
    }
  };

  useEffect(() => {
    getLoan();

    // eslint-disable-next-line
  }, []);

  return (
    <div className="mt-2">
      <div className="flex justify-center ">
        <img src="/img/logo/newlogo.png" alt="logo" className="w-16" />
      </div>

      <div className="w-11/12 mx-auto lg:w-3/12">
        <div
          className="flex items-center cursor-pointer"
          onClick={() => {
            navigate("/dashboard/loan");
          }}
        >
          <ArrowLongLeftIcon className="w-5 h-5" />
          <p className="pl-1 font-poppins">Back</p>
        </div>

        <h1 className="mb-4 text-3xl font-semibold font-ibmplexsans text-appcolorblue">
          Repay Loan
        </h1>

        {loadingloan ? (
          <div>
            <Skeletonloader height={"30rem"} />
          </div>
        ) : (
          <>
            {loans?.length === 0 ? (
              <div>
                <p className="text-sm font-normal font-poppins mb-14 text-appcolorgrey ">
                  You don't have any current loan.
                </p>
              </div>
            ) : (
              loans !== null && (
                <>
                  {loans[0]?.repayment_amount === 0 ? (
                    <>
                      <div>
                        <p className="text-sm font-normal font-poppins mb-14 text-appcolorgrey ">
                          You don't have any current loan.
                        </p>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="pb-20 mb-20">
                        <p className="mb-4 text-sm font-semibold font-poppins text-appcolorgrey ">
                          Loan Details
                        </p>

                        <div className="p-4 mb-3 rounded-lg shadow-lg bg-appcolorlightgray">
                          <div className="flex justify-between mb-4 font-poppins">
                            <p className="w-[40%] font-semibold font-poppins">
                              {" "}
                              Repayment Amount (Total)
                            </p>
                            <p className="w-[60%] flex justify-end font-poppins">
                              ₦{commaNumber(loans[0]?.repayment_amount?.total)}
                            </p>
                          </div>

                          <div className="flex justify-between mb-4 font-poppins">
                            <p className="w-[40%] font-semibold font-poppins">
                              {" "}
                              Repayment Amount (Remaining)
                            </p>
                            <p className="w-[60%] flex justify-end font-poppins">
                              ₦
                              {commaNumber(
                                loans[0]?.repayment_amount?.remaining
                              )}
                            </p>
                          </div>

                          <div className="flex justify-between mb-4 font-poppins">
                            <p className="w-[40%] font-semibold font-poppins">
                              {" "}
                              Principal
                            </p>
                            <p className="w-[60%] flex justify-end font-poppins">
                              ₦{commaNumber(loans[0]?.principal_amount)}
                            </p>
                          </div>

                          <div className="flex justify-between mb-4 font-poppins">
                            <p className="w-[40%] font-semibold font-poppins">
                              {" "}
                              Repayment Date
                            </p>
                            <p className="w-[60%] flex justify-end font-poppins">
                              {moment(loans[0]?.repayment_date).format("lll")}
                            </p>
                          </div>
                        </div>

                        <p className="mt-8 mb-4 text-sm font-semibold font-poppins text-appcolorgrey ">
                          You can repay your loan using the following options.
                        </p>

                        <div className="grid grid-cols-2 rounded-2xl bg-appcolorshade p-[0.37rem] w-full">
                          {tabs.map((item, index) => (
                            <div
                              className={`font-semibold text-xs text-center cursor-pointer py-4 font-poppins ${
                                item.active
                                  ? "bg-appcolorwhite rounded-xl"
                                  : "bg-transparent"
                              }`}
                              onClick={() => {
                                switchTabs(index);
                              }}
                              key={item.id}
                            >
                              {item.name}
                            </div>
                          ))}
                        </div>

                        <div className="mt-8">
                          <Radio.Group
                            onChange={onChange}
                            className="choose-payment-type"
                            value={methodValue}
                          >
                            {/* <Radio value={'bank'}>
					<div className="w-full p-4 mb-4 bg-no-repeat bg-cover rounded-lg bg-bankcard h-36">
						<h1 className="mb-2 text-2xl font-semibold font-poppins text-appcolorwhite">
							Bank Card
						</h1>

						<p className="text-sm font-normal font-poppins mb-14 text-appcolorwhite ">
							Bank Debit/Credit Card
						</p>
					</div>
				</Radio> */}

                            <Radio
                              value={"wallet"}
                              onClick={() => {
                                setVisibleLoanAmount(true);
                              }}
                            >
                              <div className="w-full p-4 mb-8 bg-no-repeat bg-cover rounded-lg bg-walletcard h-36">
                                <h1 className="mb-2 text-2xl font-semibold font-poppins text-appcolorblue">
                                  My Wallet
                                </h1>

                                <p className="text-sm font-normal font-poppins mb-14 text-appcolorblue ">
                                  Repay using funds in your Lucrestack Wallet
                                </p>
                              </div>
                            </Radio>
                          </Radio.Group>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )
            )}
          </>
        )}
      </div>

      {visibleLoanAmount && (
        <Repayloanamount
          setMethodValue={setMethodValue}
          reference_code={reference_code}
          visible={visibleLoanAmount}
          closeModal={closeVisibLoanAmount}
          type={tabs
            .filter((item) => item.active)[0]
            .name.split(" ")[0]
            .toLowerCase()}
          amount={
            tabs
              .filter((item) => item.active)[0]
              .name.split(" ")[0]
              .toLowerCase() === "full"
              ? loans[0]?.repayment_amount?.remaining
              : undefined
          }
        />
      )}
    </div>
  );
};

export default Repayloan;
