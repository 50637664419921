import React, { useEffect } from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../store/userSlice/userSlice";
import useOneSignal from "../../customhooks/useOneSignal";
import { RootState } from "../../store/store";

const Slash = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => ({
    subscribed: state.user.subscribed,
  }));

  const { subscribed } = state;

  const { clearUserTags } = useOneSignal(subscribed);

  useEffect(() => {
    clearUserTags();
    dispatch(logoutUser());
  }, []);

  return (
    <div className="flex flex-col justify-center h-[100vh]">
      <div className="flex justify-center ">
        <img src="/img/logo/newlogo.png" alt="logo" className="w-64" />
      </div>

      <div className="flex flex-col items-center justify-center w-9/12 mx-auto mb-8 lg:w-3/12">
        <Button
          className="mb-2 shadow-lg"
          onClick={() => {
            //@ts-ignore
            navigate("/signin");
          }}
        >
          Sign in
        </Button>

        <Button
          className="mb-8 shadow-lg purple-skel"
          onClick={() => {
            navigate("/signup");
          }}
        >
          Create an account
        </Button>

      </div>
    </div>
  );
};

export default Slash;
